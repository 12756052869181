import { afterNextRender, inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { LoginObservable } from '@app/observables/login.observable';
import { LocalStorageService } from '../local-storage/local-storage.service';
import { Account } from '@app/models/authentication/account.model';
import { Dialog } from '@angular/cdk/dialog';
import { MessageModalComponent } from '@app/modules/modals/message-modal/message-modal.component';

@Injectable({
    providedIn: 'root',
})
export class NavigationService {
    private readonly loginObservable: LoginObservable = inject(LoginObservable);
    private readonly router: Router = inject(Router);
    private readonly localStorageService: LocalStorageService =
        inject(LocalStorageService);

    constructor() {}

    navigatePage(urlCurrent: string, params?: any): void {
        if (!params) {
            this.router.navigateByUrl(urlCurrent);
            return;
        }
        this.router.navigate([urlCurrent], { queryParams: { ...params } });
    }

    goBack(): void {
        this.router.navigate(['/']);
    }
    setPositionScroll(position: number): void {
        afterNextRender(() => {
            document.documentElement.scrollTop = position;
        });
    }

    getPositionScroll(): number {
        afterNextRender(() => {
            return document.documentElement.scrollTop;
        });
        return 0;
    }

    getUrlCurrent(): string {
        return this.router.url;
    }

    navigateByUrl(urlCurrent: string): void {
        this.router.navigateByUrl(urlCurrent);
    }

    redirectLogin(
        refreshToken: string,
        accessToken: string,
        account: Account,
        havePost: boolean,
        isVerified: boolean
    ): void {
        this.loginObservable.removeLocaStore();

        if (!account.tutorials.welcomeSantoVecino) {
            this.navigatePage('/autenticacion/mapaUsuarios', {
                token: accessToken,
                latitude: account.location.coordinates[1],
                longitude: account.location.coordinates[0],
            });
            return;
        }

        this.localStorageService.setItem('token', accessToken);
        this.localStorageService.setItem('refreshToken', refreshToken);
        this.loginObservable.updateData({ ...account, havePost, isVerified });

        if (!account.tutorials.myFirstPublication) {
            this.navigatePage('/publicacion/explicacion');
            return;
        }

        this.navigatePage('/inicio');
    }
}
